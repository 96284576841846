/* Panel */
.main_panel {
  .panel {
    background: #00205B;
    border-radius: 5px;
    min-height: 90px;
    padding: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    z-index: 999;
    .airport {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      position: relative;
      span {
        &.from_error {
          display: none;
        }
        &.from_error_active {
          top: 45px;
          font-size: 12px;
          color: red;
          position: absolute;
          display: inline-block;
        }
      }
      .from,
      .to {
        input {
          width: 100%;
          height: 100%;
          background-color: transparent;
          border: medium none;
        }
          ::placeholder {
            color: $white;
          }

        background: rgba(255, 255, 255, 0.44);
        height: 42px;
        width: 250px;
        display: flex;
        align-items: center;
        padding: 0 14px;
        font-size: 12px;
        color: $white;
        cursor: pointer;
        position: relative;
        &:hover {
          color: $first_color;
        }
        &.set {
          color: $first_color;
          background: rgba(255, 255, 255, 0.85);
        }
        .select {
          height: 200px;
          overflow: auto;
          display: none;
          position: absolute;
          top: 42px;
          left: 0;
          width: 100%;
          background: #fff;
          z-index: 10000;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
          border-radius: 0 0 5px 5px;

          li {
            color: $first_color;

            line-height: 42px;
            padding: 0 14px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            &:hover {
              background: linear-gradient(
                  0deg,
                  rgba(0, 0, 0, 0.08),
                  rgba(0, 0, 0, 0.08)
                ),
                #ffffff;
            }
          }
        }
        .select.open {
          display: block;
        }
      }
      .from {
        border-radius: 5px 0 0 5px;
        margin-right: 2px;
        &:hover {
          border-radius: 5px 0 0 0;
          background: #fff;
        }
        &.focused {
          border-radius: 5px 0 0 0;
          background: #fff;
        }
      }
      .to {
        border-radius: 0 5px 5px 0;
        &:hover {
          border-radius: 0 5px 0 0;
          background: #fff;
        }
        &.focused {
          border-radius: 5px 0 0 0;
          background: #fff;
        }
      }
      /*
      .from:hover{
        background: rgba(255, 255, 255, 0.59);
      }
      .to:hover{
        background: rgba(255, 255, 255, 0.59);
      }
      */
      .change {
        position: absolute;
        top: 10px;
        left: 50%;
        margin-left: -10px;
        width: 20px;
        height: 20px;
        background: url('../assets/images/change.svg') center center no-repeat,
          #fff;
        border-radius: 10px;
        background-size: 50% 50%;
        cursor: pointer;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
      }
    }
    .date {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 202px;
      .depart,
      .arrival {
        &::placeholder {
          color: #fff;
          opacity: 1;
        }
        border: none;
        height: 42px;
        width: 100px;
        display: flex;
        align-items: center;
        padding: 0 14px;
        font-size: 12px;
        border-radius: 5px;
        cursor: pointer;
      }
      .depart {
        margin-right: 2px;
      }
      span {
        &.date_error {
          display: none;
        }
        &.date_error_active {
          top: 55px;
          font-size: 12px;
          color: $third_color;
          position: absolute;
          display: inline-block;
        }
      }
    }
    .btn {
      display: block;
      font-size: 12px;
      width: 140px;
      height: 42px;
      line-height: 42px;
      font-family: $Nunito-Regular;
      color: $white;
      background: $third_color;
      text-align: center;
      border-radius: 5px;
      border: none;
      cursor: pointer;
    }
    .hardway {
      position: absolute;
      bottom: 12px;
      left: 12px;
      display: block;
      background: url('../assets/images/hardway.svg') center left no-repeat;
      color: $white;
      font-size: 10px;
      padding-left: 18px;
    }
    .under {
      position: absolute;
      display: block;
      left: 50%;
      margin-left: -10px;
      bottom: 12px;
      width: 20px;
      height: 12px;
      margin: 0 auto;
      background: url('../assets/images/under.svg') center center no-repeat;
    }
  }
}
