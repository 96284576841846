/* Header */
header {
  .navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    @media (max-width: 1000px) {
      .sub_menu {
        margin-top: 20px;
      }
    }

    .logo {
      margin-top: 4px;
      display: flex;
      align-items: center;

      @media (max-width: 1000px) {
        margin-top: 13px;
          flex-direction: column;
          align-items: start;
        .partner {
          margin-left: 36px;
        }
        .mil {
          margin-left: 36px;
        }
      }

      .partner {
        margin-top: 2.5px;
        width: 58px;
        margin-right: 16px;
      }

      .alrosa {
        margin-bottom: -11px;
        margin-right: 11px;
        width: 163px;

        @media (max-width: 1000px) {
          margin-bottom: -5px;
          margin-right: 0;
          width: 158px;
          margin-left: 2px;
        }
      }

      .mil {
        margin-top: 1.5px;
        width: 94px;
        margin-right: 16px;
      }

      .plane_logo {
        margin-right: 6px;
      }

      .compose_logo {
        display: inherit;
        align-items: center;
      }
    }

    .logo_text {
      margin-top: 3px;
      font-size: 13px;
      font-weight: 900;
      color: $second_color;
      margin-right: 16px;
    }
  }

  .navbar ul { /* Header */
    header {
      .navbar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 90px;

        .logo img {
          height: 20px;
          cursor: pointer;
        }
      }

      .navbar ul {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .navbar .main_menu {
        li a {
          font-family: 'Nunito-Regular';
          color: $second_color;
          font-size: 12px;
          position: relative;
          padding: 0 16px 8px;
          line-height: 120%;
          cursor: pointer;
        }

        li a:hover {
          color: $third_color;
        }

        li a.active::before {
          content: ' ';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          display: block;
          width: 30px;
          height: 3px;
          border-radius: 5px;
          background: $second_color;
        }

        /*
        li a.active:hover::before{
          background: $third_color;
        }
        */
      }

      .navbar .sub_menu {
        .counter {
          display: flex;
          height: 20px;
          width: 20px;
          background: #210a7e;
          position: absolute;
          border-radius: 50px;
          margin-left: 20px;
          margin-top: -5px;
          color: $white;
          font-size: 14px;
          justify-content: center;
          align-items: center;

          &.disabled {
            display: none;
          }
        }

        li {
          width: 45px;
          text-align: center;
          margin: 0 8px;

          .logined {
            width: 25px;
            background: $second_color;
            height: 25px;
            position: relative;
            top: -2px;
            justify-content: center;
            display: flex;
            align-items: center;
            margin: 0px auto 2px;
            border-radius: 50%;
            font-size: 12px;
            color: $white;
            line-height: 14px;
            font-family: $Nunito-Regular;
          }
        }

        li a {
          font-family: $Nunito-SemiBold;
          font-size: 10px;
          line-height: 118.5%;
          color: $first_color;
          cursor: pointer;
        }

        li a:hover {
          text-decoration: underline;
        }

        li:first-child {
          margin-left: 0;
        }

        li:last-child {
          margin-right: 0;
        }

        i {
          width: 22px;
          height: 22px;
          display: block;
          margin: 0 auto 5px;
        }

        i.language {
          background: url('../assets/images/language.svg') center center no-repeat;
        }

        i.profileIcon {
          background: url('../assets/images/profile.svg') center center no-repeat;
        }

        i.cart {
          background: url('../assets/images/cart.svg') center center no-repeat;
        }
      }
    }

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
  }

  .navbar .main_menu {
    li a {
      font-family: 'Nunito-Regular';
      color: $second_color;
      font-size: 12px;
      position: relative;
      padding: 0 16px 8px;
      line-height: 120%;
      cursor: pointer;
    }

    li a:hover {
      color: $third_color;
    }

    li a.active::before {
      content: ' ';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      display: block;
      width: 30px;
      height: 3px;
      border-radius: 5px;
      background: $second_color;
    }

    /*
    li a.active:hover::before{
      background: $third_color;
    }
    */
  }

  .navbar .sub_menu {
    .counter {
      display: flex;
      height: 20px;
      width: 20px;
      background: #210a7e;
      position: absolute;
      border-radius: 50px;
      margin-left: 20px;
      margin-top: -5px;
      color: $white;
      font-size: 14px;
      justify-content: center;
      align-items: center;

      &.disabled {
        display: none;
      }
    }

    li {
      width: 45px;
      text-align: center;
      margin: 0 8px;

      .logined {
        width: 25px;
        background: $second_color;
        height: 25px;
        position: relative;
        top: -2px;
        justify-content: center;
        display: flex;
        align-items: center;
        margin: 0px auto 2px;
        border-radius: 50%;
        font-size: 12px;
        color: $white;
        line-height: 14px;
        font-family: $Nunito-Regular;
      }
    }

    li a {
      font-family: $Nunito-SemiBold;
      font-size: 10px;
      line-height: 118.5%;
      color: $first_color;
      cursor: pointer;
    }

    li a:hover {
      text-decoration: underline;
    }

    li:first-child {
      margin-left: 0;
    }

    li:last-child {
      margin-right: 0;
    }

    i {
      width: 20px;
      height: 20px;
      display: block;
      margin: 0 auto 5px;
    }

    i.language {
      background: url('../assets/images/language.svg') center center no-repeat;
    }

    i.profileIcon {
      background: url('../assets/images/profile.svg') center center no-repeat;
    }

    i.cart {
      background: url('../assets/images/cart.svg') center center no-repeat;
    }
  }
}
