.main-slider{
	overflow: hidden;
	margin: 0 auto -45px!important;
	z-index: 99;
}
.owl-main{
	.owl-item{
		overflow: hidden;
  		height: 380px;
  		// if use ratio 1440/380
  		//height: 0; 
  		//padding-top: 3.79%;
  		background-color: #ccc;
  		width: 100%;
  		div{
  			background-repeat: no-repeat;
  			background-position: center;
  			background-size: cover;
  			position: absolute;
  			left:0;
  			top:0;
  			width:100%;
  			height: 100%;
  			display: flex;
  			flex-direction: row;
  			justify-content: center;
  			align-items: center;
			h2{
  				color:$white;
  				font-size: 28px;
				line-height: 120%;
				margin: -22.25px auto 0;
				text-align: center;
  			}
  		}
	}
	.owl-nav{
		position: absolute;
		width: 100%;
		top:50%;
		margin-top: -14px;
		button{
			position: absolute;
			height: 28px;
			width: 16px;
			opacity: 0.5;
			display: block;
			&:hover{
				opacity: 1;
			}
			span{
				display: none;
			}
		}
		.owl-prev{
			left:16px;
			background: url('/assets/images/owl/prev.svg') center center no-repeat!important;
		}
		.owl-next{
			right: 16px;
			background: url('/assets/images/owl/next.svg') center center no-repeat!important;
		}
	}
	.owl-dots{
		position: absolute;
		width: 100%;
		top: 20px;
		text-align: center;
		button{
			background: rgba(255,255,255,0.5);
			height: 12px;
			width: 12px;
			margin: 0 10px;
			border-radius: 6px;
			&.active{
				background: rgba(255,255,255,0.75);
			}
			span{
				display: none;
			}
		}
	}
}