@import '../../constants.scss';

.main-slider {
  width: 100%;
  margin: 0 auto -45px !important;
}
.active {
  background-color: white;
}
.carousel-caption-center {
  color: $white;
  font-size: 28px;
  line-height: 120%;
  text-align: center;
  position: absolute;
  max-width: 482px;
  left: calc(50% - 241px);
  font-family: $Nunito-SemiBold;
  @media screen and (max-width: 1000px) {
    position: relative;
    max-width: 100%;
    left: 0;
  }
}

.carousel-container {
  display: flex;
  overflow: hidden;
  height: 380px;
  //padding-top: 3.79%;
  background-color: #ccc;
  width: 100%;
}
.dots {
  bottom: 350px;
}
.dot {
  margin: 0 10px;
}

.carousel-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.carousel-container {
  a {
    display: none;
  }
}
