main {
  margin: 24px 32px;
  position: relative;

  h2 {
    font-size: 20px;
    margin: 0 0 20px;
    color: $second_color;
  }

  p {
    font-size: 14px;
    color: $first_color;
  }
}

/* Actions banner */
.actions_block {
  padding: 24px 0;

  .actions_banner {
    width: 100%;
    height: 200px;
    background: url("/assets/images/aeroexpress_visa.svg") left 40px top 24px no-repeat,
    url("/assets/images/train.png") right 0 bottom 0 / auto 200px no-repeat,
    url("/assets/images/eclipse.svg") right -55px bottom -20px / auto 300px no-repeat,
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #ec1c24;
    border-radius: 5px;
    position: relative;

    h3 {
      position: absolute;
      top: 100px;
      left: 40px;
      font-family: "Nunito-Regular";
      color: $white;
      font-size: 20px;
      line-height: 120%;
      max-width: 60%;
    }
  }

  .view_actions {
    font-family: $Nunito-Regular;
    background: url("/assets/images/magnifying_glass.svg") left center / 14px no-repeat;
    color: $second_color;
    font-weight: 600;
    font-size: 12px;
    margin: 16px;
    padding-left: 24px;
    cursor: pointer;
    display: inline-block;

    &:hover {
      color: $third_color;
    }
  }
}

/* Actions */
.actions {
  h2 {
    color: $second_color;
    font-family: $Nunito-Regular;
    font-size: 24px;
    line-height: 140%;
    margin-bottom: 40px;
  }

  h3 {
    font-family: $Nunito-Regular;
    color: $first_color;
    font-size: 20px;
    margin-top: 16px;
  }

  h4 {
    font-family: $Nunito-Light;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
  }

  p {
    font-family: $Nunito-Regular;
    color: $first_color;
    font-weight: 300;
    font-size: 12px;
    line-height: 120%;
  }

  .btn {
    @include btn;
    min-width: 180px;
    margin: 64px 0;
  }

  .train {
    background: url("/assets/images/aeroexpress_visa.svg") left 20px top 20px no-repeat,
    url("/assets/images/train.png") left 58% bottom -20% / 270% no-repeat,
    url("/assets/images/eclipse.svg") left -20% top 50% / 150% 150% no-repeat, #bd161d;
    width: 100%;
    height: 360px;
    border-radius: 5px;
  }
}

/*Rules*/

.rules {
  z-index: 2;

  .content {
    padding: 0 40px 32px;
    color: $first_color;

    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 120%;
      margin-bottom: 24px;
      margin-top: 32px;
      color: $first_color;

      &:first-child {
        margin-top: 0;
      }
    }

    h3 {
      font-size: 12px;
      font-weight: 700;
      line-height: 19.2px;
    }

    p,
    ul {
      font-size: 12px;
      font-weight: 400;
      line-height: 14.4px;
    }

    ul {
      // list-style: inside;
      list-style-type: disc;
      margin-bottom: 16px;
      padding-left: 16px;
      // margin-top: -12px;
      li {
        line-height: 14.4px;
      }
    }

    a {
      color: $second_color;
      line-height: 19.6px;
      text-decoration: none;

      &:hover {
        color: $second_color;
        text-decoration: none;
        text-decoration: underline;
      }
    }
  }
}

.active {
  background: transparent;
}

.main-slider {
  overflow: hidden;
  margin: 0 auto -45px !important;
  z-index: 99;
}

/* Vector Map */
.vector_map {
  max-width: 1100px;
  margin: 0 -90px;
  position: relative;

  h3 {
    position: absolute;
    top: 30px;
    left: 90px;
    font-family: $Nunito-Regular;
    color: $second_color;
    font-size: 28px;
    line-height: 140%;
  }

  img {
    width: 100%;
    margin: 0 auto;
  }
}

/* Airports List */
.airports_list {
  margin: 24px auto 32px;

  h2 {
    font-size: 20px;
    margin: 0 0 32px;
  }

  .airports {
    display: flex;
    flex-flow: row wrap;
    align-content: space-between;

    .airport {
      width: 20%;
      margin-bottom: 30px;

      h3 {
        color: $second_color;
        font-size: 12px;
        font-family: $Nunito-Bold;
        cursor: pointer;
        margin-bottom: 8px;
      }

      li {
        font-family: $Nunito-Regular;
        font-size: 10px;
        line-height: 120%;
        cursor: pointer;
        margin-bottom: 6px;
      }
    }
  }
}

.airports_list {
  .container {
    max-width: 920px;
  }
}

/* Service Page*/
.service {
  color: $first_color;
  font-size: 20px;

  img {
    border-radius: 5px;
  }


  h2 {
    color: $second_color;
    font-size: 24px;
    line-height: 140%;
  }

  h3 {
    color: $first_color;
    font-family: $Nunito-Regular;
    font-size: 20px;
  }

  p {
    font-size: 12px;
    margin-bottom: 8px;
    font-family: $Nunito-Light;
  }

  .block {
    h4 {
      color: $first_color;
      font-family: $Nunito-SemiBold;
      font-size: 16px;
      padding-left: 32px;
      background: url("../assets/images/bullet.svg") left top no-repeat;
      line-height: 24px;
    }

    p {
      padding-left: 32px;
    }
  }
}

/* Profile */
.profile {
  h2 {
    color: $second_color;
    font-size: 24px;
    line-height: 140%;
  }

  p {
    font-size: 12px;
    color: $first_color;
  }

  input[type="text"] {
    font-size: 12px;
    line-height: 14px;
    border: 0;
    background: $lightgray;
    padding: 12px;
    margin-bottom: 32px;
    width: 100%;
    border-radius: 5px;
    outline: none;
  }

  .btn {
    @include btn;
    width: 100%;
  }

  .block {
    border: 1px solid $gray;
    border-radius: 5px;
    margin-bottom: 24px;
    padding: 12px;
    overflow: hidden;
  }

  h3 {
    font-size: 20px;
    color: $first_color;
    font-family: $Nunito-SemiBold;
    margin-bottom: 24px;
  }

  .qr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    h3 {
      font-size: 12px;
      color: $service;
      margin: 8px auto 16px;
    }

    img {
      margin: 12px auto;
      width: 158px;
    }

    .btn {
      background: $white;
      color: $second_color;
      font-size: 12px;
      font-family: $Nunito-Bold;
    }
  }

  .nav {
    padding: 8px 24px;

    li {
      font-size: 13px;
      line-height: 13px;
      line-height: 24px;
      padding: 12px 0 12px 32px;
      border-bottom: 1px solid $gray;
      cursor: pointer;

      &:hover {
        font-family: $Nunito-Bold;
      }

      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
      }
    }

    .history-link {
      background: url('../assets/images/history.svg') left center no-repeat;
    }

    .exit-link {
      background: url('../assets/images/exit.svg') left center no-repeat;
    }

    .privileges-link {
      background: url('../assets/images/privileges.svg') left center no-repeat;
    }
  }

  .title {
    h3 {
      font-size: 16px;
      display: block;
      float: left;
      margin-bottom: 0;
      color: $service;
      font-family: $Nunito-Regular;

      span {
        font-family: $Nunito-SemiBold;
        padding-left: 8px;
      }

      &:last-child {
        float: right;
      }
    }
  }

  .card {
    h3 {
      font-size: 14px;
      color: $service;
    }

    p {
      font-size: 12px;
      color: $gray;
    }

    .hystory {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: stretch;

      .table {
        flex-grow: 2;

        table {
          width: 100%;
          font-size: 12px;
          border-collapse: collapse;
          /*
          tr:hover{
            background: $lightgray;
          }
          */
          tr {
            border-bottom: 1px solid #c4c4c4;

            span {
              display: inline-block;
              margin: 5px;
            }
          }

          tr:last-child {
            border: 0px;
          }

          th {
            color: $service;
            text-align: center;
            font-weight: normal;
            vertical-align: middle;
            padding: 5px;
            text-align: left;

            &:first-child {
              padding-left: 0 !important;
            }

            &:last-child {
              text-align: center;
            }
          }

          td {
            color: $first_color;
            text-align: center;
            vertical-align: middle;
            padding: 8px 0 8px;
            max-width: 100px;
            text-align: left;

            .pdf {
              width: 12px;
              height: 12px;
              background: url("../assets/images/pdf.svg") center center no-repeat;
              margin: 0 auto;
              cursor: pointer;
            }

            &:last-child {
              text-align: center;
            }

            label {
              width: 14px;
              height: 14px;
              margin: 0 auto;
              display: block;
              position: relative;
              cursor: pointer;
              user-select: none;

              input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;

                &:checked ~ .checkbox {
                  background: url("../assets/images/square.svg") center center no-repeat;
                  background-size: 8px 8px;
                }
              }

              .checkbox {
                position: absolute;
                top: 0;
                left: 0;
                height: 14px;
                width: 14px;
                border: 1px solid $second_color;
              }
            }
          }
        }
      }

      .calculate {
        flex-grow: 1;
        padding-left: 32px;
        background: url("../assets/images/h_line.svg") left 12px top repeat-y;

        .total {
          height: 42px;
          padding-left: 70px;
          padding-top: 10px;
          background: url("../assets/images/total.svg") left center no-repeat;
          font-size: 24px;
          font-family: $Nunito-Regular;
          color: $first_color;
          margin: 0 0 12px;
        }

        .calcs {
          font-size: 12px;
          line-height: 16px;

          & > div {
            clear: both;
            margin-bottom: 12px;
          }

          .paid {
            color: $service;

            span {
              font-size: 16px;
            }
          }

          .miles {
            color: $third_color;
          }

          .cash {
            color: $second_color;
          }

          span {
            float: right;
          }
        }

        .btn {
          background: $white;
          color: $second_color;
          font-family: $Nunito-Bold;
          border: 1px solid rgba(121, 122, 124, 0.5);
          line-height: 32px;
          height: 32px;
          box-sizing: content-box;
          margin-bottom: 12px;

          &:hover {
            border: 1px solid $first_color;
          }
        }
      }
    }
  }
}

/* Partnership */
.partnership {
  img {
    border-radius: 5px;
  }

  form {
    margin: 0 auto 64px;
  }

  h2 {
    color: $second_color;
    font-size: 24px;
    line-height: 140%;
  }

  .btn {
    @include btn;
    width: 170px;

    &.disabled {
      opacity: 0.7;
    }
  }

  input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #797a7c;
    font-size: 12px;
    margin: 0 auto 32px;
    padding: 8px 0;

    &.error {
      border-bottom: 1px solid #ff7979;
    }
  }

  input[type="checkbox"] {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  input[type="checkbox"] + label.checkbox {
    font-size: 12px;
    margin: 12px auto 48px;
    vertical-align: top;
    display: inline-flex;

    a {
      color: $second_color;

      &:hover {
        color: $third_color;
      }
    }

    &::before {
      content: "";
      display: inline-block;
      flex-shrink: 0;
      flex-grow: 0;
      width: 18px;
      height: 18px;
      border: 1px solid $first_color;
      margin-right: 16px;
    }
  }

  input[type="checkbox"]:checked + label.checkbox::before {
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e") center center no-repeat,
    $first_color;
    background-size: 50% 50%;
  }

  textarea {
    border-radius: 5px;
    width: 100%;
    height: 80px;
    resize: none;

    &.error {
      border: 1px solid red;
    }

    &::placeholder {
      padding: 3px;
    }
  }
}

/* Services */
.services {
  &-search {
    margin-bottom: 30px;
  }

  h2 {
    color: $first_color;
    font-family: $Nunito-Regular;
    font-size: 20px;
    line-height: 42px;
    display: inline-block;
    margin: 0 0 0 10px;
  }

  .btn {
    @include btn;
    min-width: 180px;
    float: right;
    margin: 0 10px 0 0;

    &.disabled {
      opacity: 0.7;
    }
  }

  .blocks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 30px auto 0;
    overflow: hidden;

    .block {
      flex: 0 0 50%;
      box-sizing: border-box;
      padding: 0 8px 16px;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .title {
        padding: 16px;
        background: $second_color;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        h2 {
          color: $white;
          font-size: 20px;
          line-height: 20px;
          font-family: $Nunito-SemiBold;
          margin: 0 0 12px 0;
        }

        h3 {
          color: $white;
          font-size: 16px;
          line-height: 16px;
          font-family: $Nunito-Regular;
          margin: 0;
        }
      }

      ul {
        flex-grow: 1;
        border: 1px solid $gray;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 16px;
        margin: 0;

        li {
          margin-bottom: 16px;

          &:last-child {
            margin-bottom: 0;
          }

          label {
            font-size: 14px;
            line-height: 18px;
            color: $first_color;
            display: block;
            position: relative;
            cursor: pointer;
            user-select: none;
            padding-left: 24px;

            input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              height: 0;
              width: 0;

              &:checked ~ .checkbox {
                background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e") center center no-repeat,
                $first_color;
                background-size: 64% 64%;
              }
            }

            .checkbox {
              position: absolute;
              top: 0;
              left: 0;
              height: 16px;
              width: 16px;
              border: 1px solid $second_color;
              border-radius: 3px;
            }
          }
        }
      }
    }

    hr {
      border: none;
      height: 16px;
      background: url("../assets/images/line.svg") center center no-repeat;
      background-size: 100% 200%;
      margin: 0 8px 16px;
      opacity: 1;
    }
  }
}

/* Products */
.products {
  .tabs {
    margin: 0 12px 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    .tab {
      width: 25%;
      background: $white;
      padding: 8px 12px;
      color: $first_color;
      max-width: 180px;
      margin-right: 12px;
      border-radius: 10px;
      cursor: pointer;
      transition: $animation;
      border: 1px solid $gray;

      h2 {
        font-family: $Nunito-Regular;
        font-size: 16px;
        margin: 0 0 10px;
      }

      h3 {
        font-family: $Nunito-Regular;
        font-size: 12px;
        margin: 0;
      }

      &.active,
      &:hover {
        background: $second_color;
        color: $white;

        h2 {
          color: $white;
        }
      }

      &.active:hover {
        background: $third_color;
      }
    }
  }

  .blocks {
    display: none;

    &.active {
      display: block;
    }

    .block {
      border-radius: 5px;
      border: 1px solid $gray;
      padding: 12px;
      margin-bottom: 20px;
      // height: 350px;
      overflow: hidden;

      &.filter {
        .services {
          margin: 12px -12px !important;

          .service {
            width: 33% !important;
          }
        }
      }

      &.open {
        height: auto;
      }

      .title {
        h2 {
          color: $first_color;
          font-family: $Nunito-Regular;
          font-size: 20px;
          line-height: 42px;
          display: inline-block;
          margin: 0 0 8px;
        }

        .btn {
          @include btn;
          min-width: 140px;
          float: right;
          margin: 0 0 0 10px;

          &.white {
            color: $second_color;
            font-family: $Nunito-Bold;
            background: $white;
          }

          &.disabled {
            opacity: 0.5;
          }
        }

        .return {
          width: 20px;
          height: 16px;
          display: inline-block;
          background: url("../assets/images/return.svg") center center no-repeat;
          cursor: pointer;
          margin-right: 8px;
        }
      }

      .controls {
        width: 100%;
        height: 263px;
        box-sizing: border-box;
        color: $black;
        margin: 12px 0 20px;
        background: url("../assets/images/hline.svg") right top no-repeat;
        padding-bottom: 50px;
        position: relative;

        h3 {
          font-family: $Nunito-Regular;
          font-size: 16px;
          color: $first_color;
        }

        .btn {
          @include btn;
          position: absolute;
          bottom: 0;

          &.disabled {
            opacity: 0.5;
          }
        }

        ul {
          flex-grow: 1;
          margin: 24px 0;

          li {
            margin-bottom: 16px;

            &:last-child {
              margin-bottom: 0;
            }

            label {
              font-size: 14px;
              line-height: 18px;
              color: $first_color;
              display: block;
              position: relative;
              cursor: pointer;
              user-select: none;
              padding-left: 24px;

              input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;

                &:checked ~ .checkbox {
                  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e") center center no-repeat,
                  $first_color;
                  background-size: 64% 64%;
                }
              }

              .checkbox {
                position: absolute;
                top: 0;
                left: 0;
                height: 16px;
                width: 16px;
                border: 1px solid $second_color;
              }
            }
          }
        }
      }

      .services {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 12px -6px;

        .service {
          width: 25%;
          padding: 8px;
          margin-bottom: 16px;
          box-sizing: border-box;
          color: $black;
          cursor: pointer;
          border: 1px solid $white;

          .image {
            width: 100%;
            height: 0;
            padding-top: 100%;
            background-size: cover;
            background-position: center;
            border-radius: 3px;
          }

          h2 {
            font-family: $Nunito-SemiBold;
            font-size: 20px;
            line-height: 20px;
            margin: 20px 0 8px;
          }

          h3 {
            font-family: $Nunito-Regular;
            font-size: 16px;
            margin: 0 0 4px;
          }

          &:hover {
            border: 1px solid $gray;
            border-radius: 5px;
          }
        }
      }

      .extended {
        margin: 8px 0 0;

        h2 {
          font-size: 20px;
          font-family: $Nunito-SemiBold;
          margin-bottom: 20px;
        }

        ul.captions {
          li {
            font-size: 18px;
            margin: 0 0 20px;
          }
        }

        .solution {
          margin: 0 0 40px;

          h3 {
            font-size: 12px;
          }

          p {
            font-size: 12px;
          }

          &.hide {
            display: none;
          }
        }

        .counts {
          margin-bottom: 20px;
          overflow: hidden;

          .count {
            margin-bottom: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;

            h3 {
              font-size: 16px;
              font-family: $Nunito-SemiBold;
              width: 40%;
              margin: 0;
            }

            .int {
              width: 45px;
              height: 45px;
              border-radius: 50%;
              border: 2px solid $service;
              text-align: center;
              box-sizing: border-box;
              font-size: 18px;
              font-family: $Nunito-Regular;
              margin: 0 8px;
              box-sizing: content-box;
              line-height: 45px;
            }

            .min {
              background: url("../assets/images/min.svg") center center no-repeat;
            }

            .max {
              background: url("../assets/images/max.svg") center center no-repeat;
            }

            .min,
            .max {
              width: 24px;
              height: 24px;
              line-height: 45px;
              cursor: pointer;
            }

            .price {
              font-size: 16px;
              margin: 0 0 0 16px;
              font-family: $Nunito-Regular;
            }

            input {
              text-align: center;
              width: 290px;
              height: 43px;
              background: #f1f1f1;
              border-radius: 5px;
              border: none;
              padding: 5px;
            }

            .clock {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 290px;

              input {
                text-align: center;
                width: 100px;
              }

              h3 {
                font-size: 16px;
              }
            }

            select {
              background: #f1f1f1;
              border-radius: 5px;
              border: none;
              height: 43px;
              width: 290px;

              &:focus {
                outline: none;
              }
            }
          }
        }

        .total {
          height: 60px;
          padding-left: 80px;
          background: url("../assets/images/total.svg") left center no-repeat;
          font-size: 40px;
          font-family: $Nunito-Regular;
          color: $first_color;
          float: right;
        }

        .gallery {
          margin-bottom: 20px;

          ._t1897 {
            border-radius: 5px;
          }

          ._L8X8r {
            align-items: center;
          }

          ._-LJ2W {
            justify-content: center;
          }
        }
      }
    }
  }

  .show {
    background: url("../assets/images/arrow_top.svg") center top no-repeat;

    h3 {
      font-size: 10px;
      font-family: $Nunito-Regular;
      text-align: center;
      line-height: 36px;
      display: none;
      cursor: pointer;
      margin: 0;
      color: $second_color;

      &:hover {
        color: $third_color;
      }

      &:first-child {
        display: block;
      }
    }

    &.more {
      background: url("../assets/images/arrow_bottom.svg") center bottom no-repeat;

      h3 {
        display: none;

        &:last-child {
          display: block;
        }
      }
    }
  }

  .facilities {
    margin: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .facilities_item {
      margin-top: 10px;
      width: 20%;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 35px;
        height: 35px;
      }

      span {
        font-family: $Nunito-Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 117%;
        margin-top: 10px;
      }
    }
  }
}

/* Cards */
.cards {
  h2 {
    color: $second_color;
    font-family: $Nunito-Regular;
    font-size: 24px;
    line-height: 42px;
    display: inline-block;
  }

  .blocks {
    margin-bottom: 24px;
    padding-top: 12px;
    border-top: 1px solid $gray;

    h3 {
      font-size: 12px;
      line-height: 36px;
    }

    .block {
      border: 1px solid $gray;
      border-radius: 5px;
      padding: 12px;
      margin-bottom: 16px;

      h3 {
        width: 396px;
        line-height: 50px;
        color: $white;
        font-size: 20px;
        margin: -13px 0 0 -13px;
        display: inline-block;
        background: $second_color;
        padding-left: 12px;
        border-bottom-right-radius: 50px;
        border-top-left-radius: 5px;
      }

      .btn {
        @include btn;
        color: $second_color;
        font-family: $Nunito-Bold;
        background: $white;
        float: right;
        margin-top: -12px;
      }

      h4 {
        margin: 8px auto 16px;
        font-size: 24px;
        line-height: 44px;
        color: $first_color;
        // double line :)
        background: url("../assets/images/line.svg") left bottom 0 repeat-x,
        url("../assets/images/line.svg") left bottom 1px repeat-x;

        span {
          font-size: 14px;
          padding-left: 16px;
        }
      }

      ul.captions {
        li {
          font-size: 18px;
          margin: 0 0 12px;

          span {
            font-family: $Nunito-SemiBold;
            font-size: 20px;
            padding-left: 8px;
          }
        }
      }

      .total {
        height: 42px;
        padding-left: 70px;
        background: url("../assets/images/total.svg") left center no-repeat;
        font-size: 32px;
        font-family: $Nunito-Regular;
        color: $first_color;
        float: right;
        text-align: right;
        margin: 12px 0;
      }
    }

    &:first-child {
      border: none;
      padding-top: 0;
    }
  }

  .order {
    background: $second_color;
    margin: 44px auto 12px;
    border-radius: 5px;
    padding: 12px;

    h2 {
      color: $white;
      font-size: 22px;
      margin-bottom: 8px;
      display: block;
      line-height: 32px;

      span {
        display: block;
        float: right;
        font-size: 16px;
        text-align: right;
      }
    }

    h3 {
      color: $white;
      font-size: 22px;
      margin-bottom: 16px;
      line-height: 32px;

      span {
        display: block;
        float: right;
        font-size: 24px;
        font-size: $Nunito-Bold;
      }
    }

    p {
      color: $white;
      text-align: center;
      display: block;
      font-size: 12px;
      margin: 16px auto;

      span {
        display: block;
        font-size: 16px;
        font-family: $Nunito-Bold;
        @media (max-width: 992px) {
          font-size: 12px;
          display: inline;
          margin-left: 4px;
        }
      }
    }

    .btn {
      @include btn;
      width: 100%;
    }

    .input {
      position: relative;
      margin-bottom: 16px;

      input {
        height: 42px;
        width: 100%;
        border: 0;
        padding: 12px 12px 12px 12px;
        color: $white;
        border-radius: 5px;
        text-align: left;
        background-color:rgba(255, 255, 255, 0.5);

        &::placeholder {
          color: $white;
        }

        &:not(:placeholder-shown) {
          background: rgba(255, 255, 255, 0.85);
          color: $first_color;
        }
      }

      & + .welcome {
        input {
          color: $white;
          background: #8376b7;

          &::placeholder {
            color: $white;
          }
        }

        .go {
          display: none;
        }
      }

      .go {
        position: absolute;
        top: 1px;
        right: 1px;
        width: 40px;
        background: url("../assets/images/go.svg") center center no-repeat, $second_color;
        border-radius: 5px;
        height: 40px;
        width: 40px;
        cursor: pointer;

        &:hover {
          background: url("../assets/images/go.svg") center center no-repeat, $third_color;
        }
      }

      p {
        font-size: 10px;
        text-align: left;
        margin: 8px auto;
      }

      .notice {
        height: 40px;
        width: 120px;
        position: absolute;
        display: flex;
        flex-direction: row;
        align-items: center;
        top: 1px;
        right: 0px;
        color: $third_color;
        font-size: 11px;
        line-height: 12px;
        visibility: hidden;
        overflow: hidden;
        user-select: none;
        pointer-events: none;

        &.warning {
          visibility: visible;
        }
      }

      & + .warning {
        input {
          padding: 12px 174px 12px 12px;
          color: $third_color;
        }

        .notice {
          visibility: visible;
        }
      }

      @media (max-width: 992px) {
        display: inline-flex;
        input {
          width: 70%;
        }
        p {
          padding-left: 10px;
        }
        .notice {
          right: 110px;
        }
      }
    }
  }

  .btn.clear {
    @include btn;
    margin: 16px auto;
    width: 60%;
    border: 1px solid $first_color;
    background: none;
    color: $second_color;
    box-sizing: content-box;
    font-family: $Nunito-Bold;

    &:hover {
      background: $third_color;
      color: $white;
      border: 1px solid $third_color;
    }
  }

  .empty {
    margin-top: 20px;

    h2 {
      color: #210a7e;
      font-size: 24px;
      line-height: 140%;
    }

    p {
      font-family: $Nunito-Regular;
      font-size: 14px;
    }

    .btn {
      @include btn;
      color: $second_color;
      font-family: $Nunito-Regular;
      background: $white;
      width: 200px;
      border: 1px solid $second_color;
      margin-top: 20px;
    }
  }
}

.success {
  padding: 40px 40px 60px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  text-align: center;
  margin: 16px auto 56px;

  img {
    width: 270px;
  }

  h2 {
    font-size: 20px;
    line-height: 29px;
    margin: 40px auto;
    color: $second_color;
  }

  .btn {
    @include btn;
    width: 200px;
    margin: 0 auto;
  }
}

.mt-12 {
  margin-top: 12px !important;
}

.search-input {
  color: #f1f1f1 !important;

  &:hover {
    color: #434343 !important;
  }

  &.focused {
    color: #434343 !important;
  }

  &.set {
    color: #434343 !important;
  }
}

.relative {
  position: relative;
}

button {
  &.close {
    position: absolute;
    right: 16px;
    top: 16px;
    background: url("/assets/images/cross.svg") center center no-repeat;
    background-size: cover;
    height: 14px;
    width: 14px;
    cursor: pointer;
    transform-origin: 50% 50%;
    transition: 0.25s;
    border: none;

    &.white {
      background: url("/assets/images/cross-white.svg") center center no-repeat !important;
    }

    &:hover {
      transform: rotate(90deg);
    }
  }
}

.short-text {
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
