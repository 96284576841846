// Fonts
@font-face {
  font-family: 'Nunito-Light';
  src: url('../assets/fonts/Nunito-Light.ttf') format('opentype');
}
@font-face {
  font-family: 'Nunito-Regular';
  src: url('../assets/fonts/Nunito-Regular.ttf') format('opentype');
}
@font-face {
  font-family: 'Nunito-SemiBold';
  src: url('../assets/fonts/Nunito-SemiBold.ttf') format('opentype');
}
@font-face {
  font-family: 'Nunito-Bold';
  src: url('../assets/fonts/Nunito-Bold.ttf') format('opentype');
}
@font-face {
  font-family: 'Slick';
  src: url('../assets/fonts/slick.ttf') format('opentype');
}

/* Colors */
$white: #fff; // White
$black: #000; // Black
$lightgray: #f1f1f1; // Light Gray
$gray: #c4c4c4; // Gray
$first_color: #434343; // Dark Gray
$second_color: #00205B; // Violet Blue
$third_color: #E4002B; // Pink
$service: #797a7c; // Silver

/* Animations */

$animation: 0.15s;

/* Fonts */
$Nunito-Light: 'Nunito-Light';
$Nunito-Regular: 'Nunito-Regular';
$Nunito-SemiBold: 'Nunito-SemiBold';
$Nunito-Bold: 'Nunito-Bold';
$Slick: 'Slick';
@import "../constants.scss";

/* Some Resets and Presets */

* {
  font-family: $Nunito-Regular;
}
a {
  text-decoration: none;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
hr {
  background: rgba(121, 122, 124, 0.5);
  width: 100%;
  height: 1px;
  margin: 24px auto 32px;
}
input,
textarea {
  outline: none;
}

// for images
.responsive {
  min-width: 100%;
}

// Mobile version OFF!!!
//min-width: 1100px!important;

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
html,
body {
  height: 100%;
}
main {
  flex: 1 0 auto;
}

header {
  flex: 0 0 auto;
}

footer {
  flex: 0 0 auto;
}

header,
main,
footer,
.actions_block,
.main_panel,
.service {
  .container {
    max-width: 920px;
    position: relative;
  }
}

/* Scrolls */

// Mixins

@mixin btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  width: 140px;
  height: 42px;
  line-height: 42px;
  font-family: $Nunito-Regular;
  color: $white;
  background: $third_color;
  text-align: center;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}
