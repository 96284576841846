.main-loader {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;

  .content {
    position: relative;
    width: 356px;
    height: 306px;
    top: calc(50% - 306px / 2);
    margin: 0 auto;
    @media screen and (max-width: 568px) {
      top: calc(50% - 250px / 2);
      max-width: 300px;
      height: 250px;
    }
  }

  .cloud {
    &-1 {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      @media screen and (max-width: 568px) {
        img {
            width: 140px;
        }
      }
    }

    &-2 {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 3;
      @media screen and (max-width: 568px) {
        img {
            width: 130px;
        }
      }
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0turn) translateX(-174px) translateX(50%);
    }
    to {
      transform: rotate(1turn) translateX(-174px) translateX(50%);
    }
  }


  .plane {
    position: absolute;
    width: 58px;
    left: calc(50% - 58px / 2);
    top: calc(50% - 61px / 2);
    animation: spin 4s infinite linear;
    z-index: 2;
    @media screen and (max-width: 568px) {
      img {
        width: 30px;
      }
      @keyframes spin {
        from {
          transform: rotate(0turn) translateX(-140px) translateX(50%);
        }
        to {
          transform: rotate(1turn) translateX(-140px) translateX(50%);
        }
      }
    }
  }
}
