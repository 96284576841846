// Fonts
@font-face {
  font-family: 'Nunito-Light';
  src: url('./assets/fonts/Nunito-Light.ttf') format('opentype');
}
@font-face {
  font-family: 'Nunito-Regular';
  src: url('./assets/fonts/Nunito-Regular.ttf') format('opentype');
}
@font-face {
  font-family: 'Nunito-SemiBold';
  src: url('./assets/fonts/Nunito-SemiBold.ttf') format('opentype');
}
@font-face {
  font-family: 'Nunito-Bold';
  src: url('./assets/fonts/Nunito-Bold.ttf') format('opentype');
}
@font-face {
  font-family: 'Nunito-Black';
  src: url('./assets/fonts/Nunito-Black.ttf') format('opentype');
}
@font-face {
  font-family: 'Slick';
  src: url('./assets/fonts/slick.ttf') format('opentype');
}

/* Colors */
$white: #fff; // White
$black: #000; // Black
$lightgray: #f1f1f1; // Light Gray
$gray: #c4c4c4; // Gray
$first_color: #434343; // Dark Gray
$second_color: #00205B; // Violet Blue
$third_color: #E4002B; // Pink
$service: #797a7c; // Silver

/* Animations */

$animation: 0.15s;

/* Fonts */
$Nunito-Light: 'Nunito-Light';
$Nunito-Regular: 'Nunito-Regular';
$Nunito-SemiBold: 'Nunito-SemiBold';
$Nunito-Bold: 'Nunito-Bold';
$Nunito-Black: "Nunito-Black";
$Slick: 'Slick';

@mixin btn {
  display: block;
  font-size: 12px;
  width: 140px;
  height: 42px;
  line-height: 42px;
  font-family: $Nunito-Regular;
  color: $white;
  background: $third_color;
  text-align: center;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}
