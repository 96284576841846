
@media (max-width: 992px) {
	html,
	body {
		background: $lightgray;

		margin: 0 auto;
		padding: 0;
	}

	.wrapper {
		background: $white;
		//width: 375px;
		margin: 0 auto;
		padding: 0;
		//box-shadow: 0 0 24px rgb(0 0 0 / 25%);
	}

	.container {
		width: 375px;
	}
	.DayPickerInput-Overlay.from {
		left: 0px;
	}
	.DayPickerInput-Overlay.to {
		left: -50px;
	}

	main {
		margin: 24px auto 32px;
	}

	// Promotions banner
	.actions_block {
		padding: 12px 0;
		.actions_banner {
			width: calc(100% + 24px);
			height: 166px;
			background: url("/assets/images/aeroexpress_visa.svg") left 20px top 20px / 160px no-repeat,
				url("/assets/images/train.png") right -110px bottom -10px / auto 180px no-repeat,
				url("/assets/images/eclipse.svg") left 220px bottom 0 / auto 240px no-repeat,
				linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #ec1c24;
			margin: 0 -12px;
			border-radius: 0;
			position: relative;
			h3 {
				top: 70px;
				left: 20px;
				font-family: $Nunito-Light;
				font-weight: normal;
				font-size: 14px;
				max-width: 50%;
			}
		}
		.view_actions {
			margin-left: 0;
			background: url("/assets/images/magnifying_glass.svg") left center / 16px no-repeat;
			font-size: 14px;
		}
	}

	// Promotions
	.actions {
		h2 {
			color: $second_color;
			font-family: $Nunito-Regular;
			font-size: 18px;
			line-height: 110%;
			margin-bottom: 20px;
		}
		h3 {
			font-family: $Nunito-Regular;
			font-size: 18px;
			line-height: 110%;
			margin-top: 16px;
		}
		h4 {
			font-size: 14px;
			line-height: 120%;
		}
		p {
			font-weight: 300;
			font-size: 12px;
			line-height: 120%;
		}
		.btn {
			@include btn;
			min-width: 100%;
			margin: 24px 0;
		}
		.train {
			background: url("/assets/images/aeroexpress_visa.svg") left 10px top 10px no-repeat,
				url("/assets/images/train.png") right 0 bottom -10px / auto 180px no-repeat,
				url("/assets/images/eclipse.svg") left 105px bottom 0 / auto 240px no-repeat, #bd161d;
			width: calc(100% + 24px);
			border-radius: 0;
			height: 220px;
			margin: 0 -12px;
			overflow: hidden;
		}
	}

	// Promotion rules
	.rules {
		.content {
			padding: 0 4px 24px;
			h2 {
				font-size: 14px;
				line-height: 120%;
			}
			h3 {
				font-size: 12px;
				line-height: 120%;
			}
		}
	}

	// Menu
	header {
		.navbar {
			position: relative;
			margin-bottom: 90px;
			.main_menu {
				width: 375px;
				margin: 0 -12px;
				display: block;
				position: absolute;
				height: 90px;
				top: 90px;
				left: 0;
				border-top: 1px solid $service;
				ul {
					justify-content: center;
					li {
						line-height: 85px;
						a {
							font-size: 16px;
							font-weight: 600;
						}
					}
				}
			}
			.sub_menu {
				.counter {
					margin-left: 25px;
				}
				li {
					width: 52px;
					margin: 5px;
					a {
						font-size: 12px;
					}
				}
			}
		}
	}

	main {
		h2 {
			font-size: 18px;
		}
		p {
			font-size: 12px;
		}
	}

	footer {
		padding-bottom: 0;
		.container {
			padding: 0;
		}
		h3 {
			font-size: 16px;
		}
		li,
		p,
		a {
			font-size: 14px;

			img {
				width:30px;
			}
		}
		.col-6,
		.col-12 {
			padding: 20px 40px;
		}
		.col-lg-3 {
			border-bottom: 1px solid $service;
		}
		.col-lg-4 {
			border-top: 1px solid $service;
		}
		.copy {
			font-size: 12px;
		}
		li {
			margin-bottom: 12px;
		}
	}

	// Panel
	.main_panel {
		.panel {
			margin: 0 -12px;
			border-radius: 0;
			flex-wrap: wrap;
			padding-bottom: 64px;

			.airport {
				width: 350px;
				margin-bottom: 8px;
				.from,
				.to {
					height: 50px;
					width: 173px;
					.select li {
						overflow: unset;
						white-space: unset;
						border-bottom: 1px solid rgba(0, 0, 0, 0.301);
						line-height: 30px;
					}
				}
				.to {
					padding-left: 28px;
				}
				.change {
					width: 36px;
					height: 36px;
					border-radius: 18px;
					margin: -3px 0 0 -18px;
				}
				span {
					&.from_error {
						display: none;
					}
					&.from_error_active {
						top: 50px;
						font-size: 10px;
						color: red;
						position: absolute;
						display: inline-block;
					}
				}
			}
			.date {
				width: 225px;
				margin-bottom: 8px;
				margin-top: 5px;
				.arrival,
				.depart {
					height: 50px;
					width: 110.5px;
					background-image: none;
				}
				span {
					&.date_error {
						display: none;
					}
					&.date_error_active {
						top: 125px;
						font-size: 10px;
						color: red;
						position: absolute;
						display: inline-block;
					}
				}
			}
			.btn {
				margin-top: 5px;
				margin-bottom: 8px;
				height: 50px;
				line-height: 50px;
				width: 114px;
			}
			.hardway {
				bottom: 44px;
				font-size: 12px;
			}
			.under {
				width: 30px;
				height: 18px;
				background-size: cover;
				margin-left: -15px;
				bottom: 16px;
			}
		}
	}

	.airports_list {
		margin: 32px auto;
		h2 {
			text-align: center;
			font-size: 16px;
			padding: 0 24px;
		}
		.airports {
			.airport {
				padding: 0 16px;
				width: 50%;
				h3 {
					font-size: 14px;
				}
				li {
					font-size: 12px;
				}
			}
		}
	}

	.service {
		h2 {
			font-size: 18px;
			margin-bottom: 24px;
		}
		h3 {
			font-size: 18px;
		}
		h4 {
			font-size: 14px;
		}
		img {
			width: 100%;
			margin-bottom: 24px;
		}
	}

	.partnership {
		h2 {
			font-size: 18px;
		}
		.btn {
			width: 100%;
			height: 50px;
			line-height: 50px;
		}
	}

	.profile {
		h2 {
			font-size: 18px;
			margin-bottom: 24px;
		}
		.title {
			h3 {
				float: unset;
				margin: 4px 0;
				&:last-child {
					float: unset;
				}
			}
		}
		.qr {
			text-align: center;
			h3 {
				font-size: 18px;
				margin-bottom: 8px;
			}
			img {
				min-width: 260px;
			}
			.btn {
			}
		}
		.nav{
			padding: 4px 8px;
			ul{
				display: block;
				justify-content: center;
				li{
					border:0;
					line-height: normal;
					background-size: 32px;
					background-position: 16px center;
					box-sizing: border-box;
					&:first-child{
						border-right: none;
					}
				}
			}
		}
		.card {
			h3 {
				margin-top: 8px;
			}
			.hystory {
				flex-wrap: wrap;
				.table {
					td {
						font-size: 11px;
					}
					td:first-child {
						padding-left: 0 !important;
					}
					td:last-child {
						padding-right: 0 !important;
					}
					th:first-child {
						padding-left: 0 !important;
					}
				}
				.calculate {
					background: url("../assets/images/line.svg") 0 12px no-repeat;
					padding-left: 0;
					padding-top: 32px;
					position: relative;
					.btn {
						position: absolute;
						top: 28px;
						right: 0;
						width: 128px;
					}
				}
			}
		}
	}

	.services {
		h2 {
			font-size: 14px;
			margin-bottom: 24px;
		}
		.blocks {
			margin: 0;
			.block {
				flex: 0 0 100% !important;
				.title {
					h2 {
						font-size: 16px;
					}
					h3 {
						font-size: 14px;
					}
				}
				ul {
					li {
						label {
							font-size: 16px;
						}
						margin-bottom: 24px;
						&:first-child {
							margin-top: 16px;
						}
						&:last-child {
							margin-bottom: 16px;
						}
					}
				}
			}
		}
	}

	/* Products */
	.products {
		.tabs {
			justify-content: center;
			.tab {
				margin: 8px;
				width: 40%;
				h2 {
				}
			}
		}
		.blocks {
			.block {
				.title {
					overflow: hidden;
					background: url("../assets/images/line.svg") left bottom no-repeat;
					position: relative;
					padding-bottom: 12px;
					h2 {
						font-size: 16px;
						line-height: 24px;
						margin-bottom: 0;
					}
					.btn:nth-child(4) {
						float: none;
						line-height: 16px !important;
						height: 16px !important;
						margin-top: -16px !important;
					}
					.white {
						line-height: 24px;
						height: 24px;
						min-width: auto;
						width: auto;
						display: inline-block;
						margin-left: 32px;
					}
					// при условии что кнопок тут не более 2-х
					/*

					.btn:nth-child(2n){
						width: 10px!important;
						position: absolute;
						margin: 0;
						padding: 0;
						left:28px;
						top:24px;
						height: 18px;
						line-height: normal;
					}
					*/
				}
				.services {
					.service {
						width: 50% !important;
						h2 {
							font-size: 16px;
						}
						h3 {
							font-size: 12px;
						}
					}
				}
				&.filter {
					.services {
						.service {
							width: 50% !important;
							&:first-child {
								margin-left: 50%;
							}
						}
					}
				}
				.extended {
					margin-top: 24px;
					.col-6 {
						width: 100%;
					}
					.counts {
						.count {
							h3 {
								width: 33%;
							}
						}
					}
					.total {
						float: none;
						font-size: 30px;
						background-size: 43px;
						padding: 8px 0 16px 60px;
						margin-bottom: 16px;
					}
					h2 {
						font-size: 14px;
						margin-top: 8px;
						margin-bottom: 10px;
					}
					ul.captions {
						li {
							font-size: 14px;
							margin-bottom: 10px;
						}
					}
					.solution {
						h3 {
							font-size: 16px;
							font-weight: 600;
						}
					}
					.gallery {
						.box {
							width: 100%;
							height: 100%;
						}
					}
				}
				.controls {
					background: none;
					position: absolute;
					height: 240px;
				}
			}
		}
		.show {
			background-size: 20px;
			h3 {
				font-size: 14px;
			}
		}
		.facilities {
			.facilities_item {
				width: 50%;
				margin-top: 20px;
				span {
					font-size: 12px;
				}
			}
		}
	}

	// Cards

	.cards {
		h2 {
			font-size: 18px;
		}
		.blocks {
			.block {
				position: relative;
				padding-top: 32px;
				h3 {
					width: 226px;
					font-size: 18px;
					height: 38px;
					line-height: 38px;
					position: absolute;
					top: 0;
					left: 0;
					margin: 0;
				}
				.btn {
					position: absolute;
					top: 0;
					right: 0;
					margin: 0;
					width: 120px;
				}
				h4 {
					font-size: 14px;
				}
				.total {
					font-size: 30px;
					white-space: nowrap;
					margin: 0;
				}
				.captions {
					li {
						font-size: 12px !important;
						span {
							font-size: 14px !important;
						}
					}
				}
			}
		}
		.order {
			width: 375px;
			margin: 0 -12px;
			border-radius: 0;
			position: relative;
			margin-bottom: 12px;
			h2 {
				width: 200px;
				font-size: 14px;
				margin-bottom: 0;
				span {
					font-size: 16px;
				}
			}
			h3 {
				width: 200px;
				font-size: 14px;
				span {
					font-size: 20px;
				}
			}
			.btn {
				width: 35%;
				position: absolute;
				right: 16px;
				top: 18px;
				height: 50px;
				line-height: 50px;
			}
		}
		.clear {
			position: absolute;
			top: -12px;
			right: 16px;
			height: 32px !important;
			width: 145px !important;
			line-height: 32px !important;
		}
	}
}
